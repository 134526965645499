<template>
  <validation-observer
    ref="form"
    tag="div"
  >
    <div class="item-general-form">
      <div>
        <b-row class="align-items-center">
          <b-col
            md="6"
            class="mb-1"
          >
            <ok-text-input
              v-model="form.name"
              size="md"
              :label="$t('fields.name')"
              is-required
            />
          </b-col>
          <b-col
            md="6"
            class="mb-1"
          >
            <ok-text-input
              v-model="form.username"
              size="md"
              :label="$t('fields.username')"
              is-required
            />
          </b-col>
          <b-col
            md="6"
            class="mb-1"
          >
            <ok-text-input
              v-model="form.email"
              size="md"
              :label="$t('fields.email')"
              rules="email"
            />
          </b-col>
          <b-col
            md="6"
            class="mb-1"
          >
            <ok-text-input

              size="md"
              :label="$t('branches.phone-number')"
            >
              <ok-phone-number-input v-model="form.phone" />
            </ok-text-input>
          </b-col>
          <b-col
            md="6"
            class="mb-1"
          >
            <password-input
              v-model="form.password"
              size="md"

              :label="$t('fields.password')"
            />
          </b-col>
          <b-col
            md="6"
            class="mb-1"
          >
            <b-form-checkbox
              v-model="form.status"
              switch
            >
              {{ $t('store.active') }}
            </b-form-checkbox>
          </b-col>
          <b-col
            md="12"
            class="mb-1"
          >
            <branch-select
              v-model="form.branches"
              :label="$t('assistants.branches')"
              is-required
              multiple
            /></b-col>
        </b-row>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import { BRow, BCol, BFormCheckbox } from 'bootstrap-vue'
import pick from 'lodash/pick'
import { ValidationObserver } from 'vee-validate'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'
import PasswordInput from '@/@bya3/components/inputs/PasswordInput'
import BranchSelect from '@/@bya3/components/inputs/BranchSelect'
import OkPhoneNumberInput from '@/@bya3/components/inputs/OkPhoneNumberInput'
import EditAssistantMixin from '../mixinx/edit-assistant-mixin'

export default {
  name: 'AssistantsGeneralForm',
  components: {
    OkPhoneNumberInput,
    BranchSelect,
    PasswordInput,
    OkTextInput,
    ValidationObserver,
    BRow,
    BFormCheckbox,
    BCol,
  },
  mixins: [EditAssistantMixin],
  data() {
    return {
      loading: false,
      error: null,
      submitting: false,
      form: {},
      modified: false,
      modifiedReady: true,
    }
  },
  computed: {
    editMode() {
      return !!this.$route.params?.id
    },
  },
  watch: {
    active(active) {
      if (active) {
        this.init()
      }
    },
  },
  methods: {
    prepareForSubmit() {
      const keys = ['phone', 'username', 'status', 'email', 'name', 'password', 'branches']
      const values = pick(this.form, keys)
      const { permissions } = this.form
      return {
        ...values,
        permissions,
      }
    },
    async isValid() {
      return this.$refs.form.validate()
    },
  },
}
</script>
