<template>
  <div class="item-localization-form mt-5">
    <div class="item-localization-form-content">
      <b-row class="align-items-center">
        <b-col
          v-for="(permission, idx) in options"
          :key="idx"
          md="6"
          sm="12"
          class="mb-1"
        >
          <b-form-checkbox
            v-model="permissions[permission.value]"
            switch
          >
            {{ permission.text }}
          </b-form-checkbox>
        </b-col>
      </b-row>

    </div>
  </div>
</template>

<script>
import { BRow, BCol, BFormCheckbox } from 'bootstrap-vue'
import ASSISTANT_PERMISSIONS from '@/constants/assistant-permissions'
import EditAssistantMixin from '../mixinx/edit-assistant-mixin'

export default {
  name: 'AssistantsPermissionsForm',

  components: {
    BRow,
    BCol,
    BFormCheckbox,
  },
  mixins: [EditAssistantMixin],
  data() {
    return {
      permissions: {},
      options: [],
    }
  },
  mounted() {
    this.permissions = this.form?.permissions ?? {}
    this.options.forEach(permission => { this.permissions[permission.value] = true })
    this.formatPermission()
  },
  methods: {
    prepareForSubmit() {
      return this.permissions
    },
    setUser() {
      if (this.form?.permissions) {
        this.permissions = {}
        Object.keys(this.form?.permissions ?? {}).forEach(permission => { this.permissions[permission] = this.form.permissions[permission] })
      }
    },
    formatPermission() {
      this.options = Object.values(ASSISTANT_PERMISSIONS)
        .map(permission => ({ value: permission, text: this.$t(`assistants.permissions.${permission}`) }))
    },
    reset() {
      this.form.user_id = null
      this.permissions = {}
      this.options.forEach(permission => { this.permissions[permission.value] = true })
    },
  },

}
</script>
