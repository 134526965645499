<template>
  <div>
    <fetching-data-spinner
      :error="fetchError"
      :loading="fetch"
      :loading-title="$t('general.loading-data')"
      enable-retry
      @retry="init"
    />
    <div v-if="!fetch">
      <validation-observer ref="form">

        <empty-card
          :title="$t(isEdit?'forms.edit':'forms.add')"
        >
          <template slot="title">
            <div
              class="d-flex align-items-center"
            >
              <b-button
                variant="link"
                :disabled="submitting"
                @click="$router.push({name:'owner:assistants'})"
              >
                <span class="text-dark">{{ $t('forms.back') }}</span>
              </b-button>
              <b-button
                variant="dark"
                :disabled="submitting"
                @click="submit()"
              >
                <span :class="{'pr-1': submitting}">{{ $t('forms.save') }}</span>
                <b-spinner
                  v-if="submitting"
                  small
                />
              </b-button>
            </div>
          </template>
          <b-tabs
            v-model="tab"
            class="mt-3"
            justified
            pills
          >
            <b-tab
              :title="$t('store.general')"
              :title-link-class="isGeneralValid ? '' : 'bg-danger text-white'"
            >
              <assistants-general-form
                ref="general"
                v-model="form"
                :active="tab === 0"
              />
            </b-tab>
            <b-tab
              :title="$t('assistants.permissions.title')"
            >
              <assistants-permissions-form
                ref="permissions"
                v-model="form"
                :active="tab === 1"
              />
            </b-tab>

          </b-tabs>
        </empty-card>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import {
  BButton, BTabs, BTab, BSpinner,
} from 'bootstrap-vue'
import cloneDeep from 'lodash/cloneDeep'
import { ValidationObserver } from 'vee-validate'
import ErrorHandler from '@/utils/error-handler'
import FetchingDataSpinner from '@/@bya3/components/utils/FetchingDataSpinner'
import EmptyCard from '@/@bya3/components/cards/EmptyCard'
import AssistantsPermissionsForm from '@/views/assistants/components/AssistantPermissionsForm'
import AssistantsGeneralForm from '@/views/assistants/components/AssistantGeneralForm'
import AssistantApi from '@/api/assistant-api'

export default {
  name: 'ProcessAssistantsPage',
  components: {
    AssistantsGeneralForm,
    AssistantsPermissionsForm,
    EmptyCard,
    FetchingDataSpinner,
    ValidationObserver,
    BButton,
    BTabs,
    BTab,
    BSpinner,
  },
  data() {
    return {
      fetch: true,
      fetchError: null,
      modified: false,
      modifiedReady: true,
      form: { status: true },
      submitting: false,
      tab: 0,
      isGeneralValid: true,
    }
  },
  computed: {
    isEdit() {
      return !!this.$route.params?.id
    },
  },
  watch: {
    value(visible) {
      this.error = null
      this.tab = 0
      if (visible) {
        setTimeout(() => { this.modifiedReady = true; this.modified = false }, 1000)
      }
    },
    form: {
      deep: true,
      handler() {
        if (this.modifiedReady) {
          this.modified = true
        }
      },
    },
  },
  async mounted() {
    await this.init()
  },
  methods: {
    async init() {
      if (!this.isEdit) {
        this.fetch = false
        this.form.branches = [this.$store.getters['shop/currentBranchId']]
        return
      }
      const { id } = this.$route.params
      this.fetch = true
      this.fetchError = null
      this.modifiedReady = false
      this.modified = false
      await AssistantApi.getById(id).then(async res => {
        this.form = res?.assistant ? cloneDeep(res.assistant) : { status: 1 }
        this.form.status = !!this.form.status
        if (!(this.form?.branches ?? []).length) {
          this.form.branches = [this.$store.getters['shop/currentBranchId']]
        }
        // eslint-disable-next-line no-multi-assign
        setTimeout(() => { this.modifiedReady = true; this.modified = false }, 1000)
      })
        .catch(e => { this.error = ErrorHandler.getErrorDetails(e) })
        .finally(() => { this.fetch = false })
    },
    async submit() {
      await new Promise(resolve => setTimeout(resolve, 200))
      const valid = await (this.$refs.form.validateWithInfo())
      if (!valid.isValid) {
        this.isGeneralValid = await this.$refs.general.isValid()

        this.error = { title: this.$tc('errors.field-invalid', 2), message: ErrorHandler.getValidationErrors(valid.errors) }
        return
      }

      // eslint-disable-next-line no-multi-assign
      this.isGeneralValid = true
      const general = this.$refs.general.prepareForSubmit()
      general.permissions = this.$refs.permissions.prepareForSubmit()
      const payload = {
        ...general,
      }
      if (this.isEdit) payload.id = this.$route.params.id
      this.submitting = true
      AssistantApi.process(payload)
        .then(response => {
          this.$notifySuccess(response.message)
          this.$router.push({ name: 'owner:assistants' })
        })
        .catch(error => { this.error = error })
        .finally(() => { this.submitting = false })
    },
    handleClose() {
      this.$emit('input', false)
    },
  },
}
</script>
<style lang="scss">

.nav{
  &.nav-pills.nav-justified .nav-link{
    border-radius: 0;
  }
  &.nav-pills.nav-justified .nav-link:not(.active){
    background-color: #D8D6DD;
    border-left: 1px solid #ffffff;
  }
}
</style>
