import $http from '@/libs/axios'

export default class AssistantApi {
  static async delete(id: number) {
    return (await $http.delete(`assistants/${id}`)).data
  }

  static async list() {
    return (await $http.get('assistants')).data
  }

  static async getById(id: string) {
    return (await $http.get(`assistants/${id}`)).data
  }

  static async process(data: any) {
    return (await $http.post('assistants', data)).data
  }
}
